.TrackMap {
  margin: 15px 20px;
  .spinner {
    display: flex;
    height: 100vh;
    align-items: center;
    justify-content: center;
  }
  .service {
    background-color: var(--primary-light);
    color: var(--primary);
    padding: 20px 28px;
    border: 1px solid white;
    border-radius: 25px;
    margin: 20px 0;
  }
  .heading {
    font-size: 25px;
    font-weight: 600;
  }
  .milestone {
    margin-left: 15px;
    font-size: 20px;
    font-weight: 400;
  }
}