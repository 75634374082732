.navigation {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-size: 18px;
  height: 100%;
  background-color: var(--primary-light);
  border-top-left-radius: 25px;
  border-bottom-left-radius: 25px;
  .navLink {
    color: var(--purple);
    padding: 40px 20px;
  }
  .active {
    background-color: var(--purple);
    color: var(--primary-light);
  }
  .active.first {
    border-top-left-radius: 25px;
  }
  .active.last {
    border-bottom-left-radius: 25px;
  }
}

@media only screen and (max-width: 768px) {
  .navigation {
    border-bottom-left-radius: 0px !important;
    .navLink {
      padding: 20px !important;
    }
    .active.first {
      border-top-right-radius: 25px;
    }
    .active.last {
      border-bottom-left-radius: 0px !important;
    }
  }
}