.track-map {
  padding: 30px 0;
  .timeline-item {
    margin-bottom: 8px;
    display: flex;
    align-items: flex-start;
    grid-gap: 3rem;
    gap: 3rem;
    position: relative;
    
    .vertical-divider-container {
      margin-top: 12px;
      .vertical-divider {
        position: relative;
        height: 60px;
        border-right: 0.145rem solid #4a626d;
      }
      .vertical-divider.last {
        height: 0px;
      }
      .vertical-divider::before {
        content: '';
        position: absolute;
        top: -12px;
        left: -2.5px;
        height: 8px;
        width: 8px;
        background: var(--primary);
      }        
    }

    .invite-subtitle {
      margin-top: -5px;
      width: 70%;
      color: rgba(0, 0, 0, 0.4) !important;
    }
    .active {
      color: var(--primary) !important;
    }
  }
}