.contain {
  height: 100svh;
  position: relative;
  .login {
    width: 50%;
    margin: 0 auto;
    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    background-color: var(--purple);
    color: white;
    border-radius: 25px;
    .img {
      border-top-left-radius: 25px;
      border-bottom-left-radius: 25px;
    }
    .formLogin {
      padding-right: 25px;
      .login-link {
        text-decoration: none;
        color: white;
        font-size: 0.9rem;
      }
    }
    .loginButton {
      background-color: var(--primary-light) !important;
      color: var(--primary);
      margin: 10px 0;
      border-radius: 5px;
    }
    .eyes {
      cursor: pointer;
      margin: 10px 0;
      width: 100%;
      padding: 12px 5px;
      border-radius: 5px;
    }
    .loginImg {
      border-top-left-radius: 25px;
      border-bottom-left-radius: 25px;
    }
  }
}

.authlog {
  background-image: url("../../assets/loginBG.jpg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 100svh;
  border-radius: 25px;
  border-radius: 25px;
}

.auth.reg {
  background-image: url("../../assets/registerBG.jpg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 100%;
  border-radius: 25px;
}

.register {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-radius: 25px;
  flex: 1;
  padding: 50px 0;
  .eyes {
    cursor: pointer;
    margin: 10px 0;
    width: 100%;
    padding: 12px 5px;
    border-radius: 5px;
  }
  .form {
    background-color: #f6f6f6;
    border-radius: 25px;
    padding: 50px 25px;
  }
}

@media only screen and (max-width: 768px) {
  .login {
    width: 90% !important;
    border-radius: 25px;
    .loginImg {
      border-top-right-radius: 25px;
      border-bottom-left-radius: 0px !important;
    }
    .formLogin {
      padding: 25px;
    }
  }
}
