.error {
  background-color: darkred;
  color: white;
  width: 50%;
  margin: 20px 0;
  border-radius: 5px;
}

.success {
  background-color: #90EE90;
  color: rgb(2, 121, 18);
  width: 50%;
  margin: 20px 0;
  border-radius: 5px;
}

.error p, .success p {
  padding: 20px;
}