.business-form {
  // width: 100vh;
  background-color: #f6f6f6;
  border-radius: 25px;

  .form {
    display: flex;
    // justify-content: center;
    // align-items: center;
    margin: 10% 5%;
  
    .next {
      margin: 10px 0;
    }
  
    .previous {
      margin: 10px 0;
    }
  }
  .bstitle {
    display: none;
  }
}

@media only screen and (max-width: 768px) {
  .business-form {
    margin: 0 30px;
    .form {
      margin: 0% 5%;
    }
  }
  .bssidebar {
    display: none;
  }
  .bstitle {
    display: flex !important;
    justify-content: center;
    font-size: 1.9rem;
    padding-top: 25px;
    font-weight: 500;
    background-color: var(--primary);
    color: white;
    padding-bottom: 20px;
    border-top-right-radius: 25px;
    border-top-left-radius: 25px;
  }
}