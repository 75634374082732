.trade-license {
  .serviceHeading {
    color: var(--primary);
    margin: 10px 0;
  }
  .form {
    padding: 10px 0;
  }
}

.spinner {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}