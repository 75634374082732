.service-card {
  background-color: var(--primary-light);
  padding: 20px;
  margin: 20px 0;
  border-radius: 20px;
  box-shadow: var(--primary-light) 0px 8px 24px;
  .getStarted {
    text-decoration: none;
    background-color: var(--primary);
    color: white;
    padding: 10px 18px;
    border-radius: 20px;
    font-size: 0.7rem
  }
}