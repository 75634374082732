:root {
  --primary: rgba(89, 61, 184, 255);
  --purple: rgba(87,79,165,255);
  --primary-light: rgb(246, 235, 255);
}

html, body {
  overflow-x: hidden;
  overflow-y: hidden;
}

button {
  background: none;
	color: inherit;
	border: none;
	padding: 0;
	font: inherit;
	cursor: pointer;
	outline: inherit;
}

input {
  width: 100%;
  margin: 10px 0;
  border: 1px solid rgba(0, 0, 0, 0.4) !important;
  border-radius: 5px;
  outline: none;
  padding: 5px 5px;
  transition: 0.3s ease-in-out;
}

select {
  width: 100%;
  margin: 10px 0;
  border: 1px solid rgba(0, 0, 0, 0.4) !important;
  border-radius: 5px;
  outline: none;
  padding: 5px 5px;
  transition: 0.3s ease-in-out;
}

select:focus {
  border: 1px solid var(--primary) !important;
  transition: 0.3s ease-in-out;
}

input:focus {
  border: 1px solid var(--primary) !important;
  transition: 0.3s ease-in-out;
}

select {
  width: 100%;
  margin: 10px 0;
}

label {
  margin-top: 10px;
}

.button-purple {
	width: 100%;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  color: #fff;
  border-radius: 60px;
  background-color: var(--primary);
  margin-top: 32px;
  padding: 18px 0;
  text-transform: none;
  letter-spacing: 0;
}

.eyes {
  cursor: pointer;
  margin: 10px 0;
  width: 100%;
  padding: 12px 5px;
  border-radius: 5px;
}

.button-light {
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  color: var(--primary);
  border-radius: 60px;
  background-color: white;
  margin-top: 32px;
  padding: 18px 38px;
  text-transform: none;
  letter-spacing: 0;
}

.button {
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.05em;
  position: relative;
  text-transform: uppercase;
  display: inline-flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  user-select: none;
  cursor: pointer;
  border-radius: 0px;
  padding: 0px 12px;
  min-height: 40px;
  font-family: inherit;
  transition: background-color 0.2s ease 0s, transform 0.1s ease 0s;
  box-sizing: border-box;
  background-color: var(--primary);
  color: white;
  width: 100%;
  border-radius: 5px;
}

.spinner {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.App {
  height: 100vh;
  width: 100%;
  display: flex;
  background-color: var(--primary);
  .sidebar {
    display: flex;
    height: 100%;
    .branding {
      padding: 20px;
    }
    .css-dip3t8 {
      background-color: var(--primary);
      color: white;
    }
    .css-1wvake5 {
      border-right-width: 0px;
    }
    .css-12vkui9 {
      background-color: var(--primary);
    }
    .ps-menu-button:hover {
      background-color: var(--primary-light);
      color: var(--primary);
    }
    .css-1l5fxv3 {
      background-color: var(--primary);
    }
  }
  .contents {
    background-color: white;
    width: 100%;
    margin: 10px;
    border-radius: 25px;
    color: var(--primary);
    display: flex;
    .routes {
      width: 100%;
      overflow-y: auto;
    }
    .routes::-webkit-scrollbar {
      width: 6px;
      height: 6px;
    }
    .routes::-webkit-scrollbar-track {
      border-radius: 10px;
      background: rgba(0,0,0,0.1);
    }
    .routes::-webkit-scrollbar-thumb{
      border-radius: 10px;
      background: rgba(0,0,0,0.2);
    }
    .routes::-webkit-scrollbar-thumb:hover{
      background: rgba(0,0,0,0.4);
    }
    .routes::-webkit-scrollbar-thumb:active{
      background: rgba(0,0,0,0.5);
    }
    .track-mapper::-webkit-scrollbar {
      width: 6px;
      height: 6px;
    }
    .track-mapper::-webkit-scrollbar-track {
      border-radius: 10px;
      background: rgba(0,0,0,0.1);
    }
    .track-mapper::-webkit-scrollbar-thumb{
      border-radius: 10px;
      background: rgba(0,0,0,0.2);
    }
    .track-mapper::-webkit-scrollbar-thumb:hover{
      background: rgba(0,0,0,0.4);
    }
    .track-mapper::-webkit-scrollbar-thumb:active{
      background: rgba(0,0,0,0.5);
    }
    .track-mapper {
      background-color: #fef9fe;
      width: 40%;
      overflow-y: auto;
      border-top-right-radius: 25px;
      border-bottom-right-radius: 25px;
    }
  }
}

@media only screen and (max-width: 768px) {
  .App {
    .contents {
      .track-mapper {
        display: none;
      }
    }
  }
}